<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="60%" persistent="">
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        {{
                            $store.state.bodyDetails.dialogType == "add"
                                ? ""
                                : "تعديل"
                        }}
                        غرفة
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters class="mt-6">
                            <v-col cols="12" md="4" class="pl-2">
                                الطابق
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                                <floors-list label="اختار الطابق" />
                            </v-col>

                            <v-col cols="12" md="4" class="pl-2">
                                نوع الغرفة
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                                <room-type-list label="اختار نوع الغرفة" />
                            </v-col>

                            <v-col cols="12" md="4">
                                <small class="font-weight-bold">
                                    رقم الغرفة
                                    <span class="red--text"
                                        >* يجب ان يكون فريدة من نوعةا
                                    </span>
                                </small>
                                <v-text-field
                                    v-model="body.roomNumber"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="رقم الغرفة"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="4" class="pl-2">
                                عدد الأَسرًة داخل الغرفة
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                                <vue-numeric-input
                                    class="numeric-input"
                                    v-model="body.bedCount"
                                    :min="0"
                                    :rules="[required()]"
                                    align="center"
                                >
                                </vue-numeric-input>
                            </v-col>

                            <v-col cols="12" md="4">
                                السعر بالدينار العراقي

                                <v-text-field
                                    v-model="body.totalPrice"
                                    @input="numer(body.totalPrice)"
                                    suffix="د.ع"
                                    hide-details="auto"
                                    outlined
                                    required
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="000 30  دينار عراقي "
                                />
                            </v-col>

                            <!-- description -->
                            <v-col cols="12" md="12" class="mt-4">
                                <small class="font-weight-bold mb-4">
                                    اكتب وصفاً للغرفة
                                </small>
                                <v-textarea
                                    v-model="body.description"
                                    required
                                    outlined
                                    filled
                                    label="لا توجد ملاحضات"
                                    color="secondary"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" md="12" class="mt-2">
                                <v-btn
                                    type="submit"
                                    :disabled="
                                        !body.roomNumber ||
                                            !body.bedCount ||
                                            !$store.state.floorId ||
                                            !$store.state.floorId
                                    "
                                    :loading="loading"
                                    x-large
                                    color="primary"
                                >
                                    حفظ
                                </v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                >
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import { mapState } from "vuex";
import FloorsList from "@/components/lists/FloorsList.vue";
import RoomTypeList from "../../components/lists/RoomTypeList.vue";
import VueNumericInput from "vue-numeric-input";
import numeral from "numeral";

export default {
    data: () => ({
        value: 1234,
        valid: true,
        body: {},
        loading: false,
        ...validations
    }),
    components: {
        FloorsList,
        RoomTypeList,
        VueNumericInput
    },
    computed: {
        ...mapState(["bodyDetails"])
    },

    created() {
        this.body = {};
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            this.body = this.bodyDetails;
            this.$store.state.floorId = this.$store.state.bodyDetails.floorId;
            this.$store.state.roomTypeId = this.$store.state.bodyDetails.roomTypeId;
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },
        addItem() {
            this.loading = true;
            this.body.floorId = this.$store.state.floorId;
            this.body.roomTypeId = this.$store.state.roomTypeId;
            this.$GenericService
                .create("room", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    if (err.data.status == "404") {
                        this.$store.dispatch("setSnackbar", {
                            text: `رمز الغرفة ( ${this.body.roomNumber} ) بالفعل ةو موجود `,
                            color: "error"
                        });
                    } else {
                        this.$store.dispatch("setSnackbar", {
                            text: ` ${err.data.message}`,
                            color: "error"
                        });
                    }
                })
                .finally(() => (this.loading = false));
        },

        editItem() {
            this.loading = true;
            this.body.floorId = this.$store.state.floorId;
            this.body.roomTypeId = this.$store.state.roomTypeId;
            const data = Object.entries(this.body).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
            ); //remove null fields

            this.$GenericService
                .update(`room/edit/${this.body.id}`, data)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        close() {
            this.$store.dispatch("setDialog");
            this.body = {};
            this.$store.state.floorId = "";
            this.$store.state.roomTypeId = "";
            this.resetValidation();
            this.$store.dispatch("setBodyDetails", {});
        },

        numer(x) {
            this.body.totalPrice = numeral(x).format("0,0");
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>

<style lang="scss">
// .currency-input {
//     color: rgba(0, 0, 0, 0.87);
//     background: #f1f4f5;
//     font-size: large;
//     font-weight: 600;
//     font-size: 16px;
//     max-width: 100%;
//     padding: 1px 5px;
//     min-height: 40px;
//     border-radius: 4px;
//     min-width: 0;
//     width: 100%;
//     border: solid 1px #656666;
//     transition-duration: 0.3s;
//     transition-property: color, border-width;
//     transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
// }
</style>
